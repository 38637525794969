.icon {
  color: black;
  cursor: pointer;
}

td.cell-collapse {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fa-button {
  background: none;
  padding: 0px;
  border: none;
}

.table-centered {
  text-align: center;
}

.bottom-margin {
  margin-bottom: 0.25em;
}

.below-input {
  display: block;
}

.disabled-row {
  color: gray;
  opacity: 0.5;
}